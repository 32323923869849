import React from 'react';
import Routes from './routes';
import styles from './App.module.scss';
import LayoutService from './common/services/layout.service';
import {
  Context as GlobalContext,
  GlobalContextType,
} from './context/global.context';
import LoaderComponent from './components/loader/loader.component';
import AnimationService from './common/services/animatin.service';
import { classnames } from './common/general-utilities/general-utilities';
import MessageComponent from './components/message/message.component';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import SideNavComponent from './components/side-nav/side-nav.component';
import DrawerComponent, {
  DRAWER_DIRECTION,
} from './components/model/drawer.component';
import { CancelSVG, shareLinks } from './common/constant/resume-data.constant';
import QnAService from './common/services/q-n-a.service';

interface IAppState {
  isLoadingImages: boolean;
  isLoadingResumeData: boolean;
  isInstallPromptOpen: boolean;
  shouldShowInstallPrompt: boolean;
}

export default class App extends React.PureComponent<any, IAppState> {
  static contextType = GlobalContext;
  public state: IAppState = {
    isLoadingImages: true,
    isLoadingResumeData: true,
    isInstallPromptOpen: false,
    shouldShowInstallPrompt: true,
  };

  componentDidMount() {
    const {
      setLayoutSize,
      setResponsiveMode,
      addScroll,
      setPersonProps,
      setDarkMode,
      setScreenProps,
    }: GlobalContextType = this.context;
    LayoutService.bootstrap(
      setLayoutSize,
      setResponsiveMode,
      setDarkMode,
      setScreenProps
    );
    AnimationService.bootstrap(addScroll, setPersonProps);
    this.fetchResumeData();
    LayoutService.preloadImages()
      .then(() => {
        this.setState({ isLoadingImages: false });
      })
      .catch(() => {
        this.setState({ isLoadingImages: false });
      });
    serviceWorkerRegistration.register({
      onUpdate: this.onServiceWorkerUpdate,
      beforeInstallPromptHandler: this.beforeInstallPromptHandler,
    });
  }

  render() {
    const {
      state: { responsiveMode, isDark, isShareModelVisible, isSideBarVisible },
      setShareModelVisibility,
      setSideNavBarVisibility,
    }: GlobalContextType = this.context;
    if (this.state.isLoadingResumeData || this.state.isLoadingImages) {
      return <LoaderComponent />;
    }

    return (
      <div
        data-theme={isDark ? 'dark' : 'light'}
        className={classnames(styles.appContainer, responsiveMode)}
      >
        {this.updateNotification}
        {this.installPrompt}
        <DrawerComponent
          isOpen={isSideBarVisible}
          handleClose={setSideNavBarVisibility.bind(this, false)}
          responsiveMode={responsiveMode}
          isDark={isDark}
          outerScopeContent={this.fullScreenCloseBtn}
        >
          {this.sideNavContent}
        </DrawerComponent>
        <DrawerComponent
          isOpen={isShareModelVisible}
          handleClose={setShareModelVisibility.bind(this, false)}
          responsiveMode={responsiveMode}
          isDark={isDark}
          direction={DRAWER_DIRECTION.BOTTOM}
        >
          {this.shareButtonsContent}
        </DrawerComponent>
        <Routes responsiveMode={responsiveMode} />
      </div>
    );
  }

  private fetchResumeData() {
    const { setResumeData, setQnAServiceInstance }: GlobalContextType = this.context;
    fetch('resume-data.json')
      .then((r) => r.json())
      .then(
        (res) => {
          setResumeData(res);
          const qnaService = new QnAService(res);
          setQnAServiceInstance(qnaService);
          this.setState({ isLoadingResumeData: false });
        },
        (_error) => {
          this.setState({ isLoadingResumeData: false });
        }
      );
  }

  private onServiceWorkerUpdate = (registration: ServiceWorkerRegistration) => {
    const { setPWAProps }: GlobalContextType = this.context;
    setPWAProps({
      waitingWorker: registration && registration.waiting,
      newVersionAvailable: true,
    });
  };

  private beforeInstallPromptHandler = (event: any) => {
    event.preventDefault();
    if (this.state.shouldShowInstallPrompt) {
      const { setPWAProps }: GlobalContextType = this.context;
      setPWAProps({
        installPromptEvent: event,
      });
      this.setState({ isInstallPromptOpen: true });
    }
  };

  private handleOnUpdateNewVersioin = () => {
    const {
      state: { pwa },
      setPWAProps,
    }: GlobalContextType = this.context;
    pwa.waitingWorker &&
      pwa.waitingWorker.postMessage({ type: 'SKIP_WAITING' });
    setPWAProps({ newVersionAvailable: false });
    window.location.reload();
  };

  private get updateNotification() {
    const {
      state: { responsiveMode, pwa },
    }: GlobalContextType = this.context;
    return (
      <MessageComponent
        onSuccess={this.handleOnUpdateNewVersioin}
        onCancel={this.handleOnUpdateNewVersioin}
        responsiveMode={responsiveMode}
        isOpen={pwa.newVersionAvailable}
        msg={
          'New version available! Reload the webapp to see the latest changes.'
        }
        showCancelBtn={false}
      />
    );
  }

  private handleInstallPrompt = (key: number) => {
    const {
      state: { pwa },
    }: GlobalContextType = this.context;
    if (key && pwa.installPromptEvent) {
      pwa.installPromptEvent?.prompt();
    }
    this.setState({
      isInstallPromptOpen: false,
      shouldShowInstallPrompt: false,
    });
  };

  private get installPrompt() {
    const {
      state: { responsiveMode },
    }: GlobalContextType = this.context;
    return (
      <MessageComponent
        onSuccess={this.handleInstallPrompt.bind(this, 1)}
        onCancel={this.handleInstallPrompt.bind(this, 0)}
        responsiveMode={responsiveMode}
        isOpen={this.state.isInstallPromptOpen}
        msg={'Install App?'}
        showCancelBtn={true}
      />
    );
  }

  private get fullScreenCloseBtn() {
    const {
      state: { screen },
    }: GlobalContextType = this.context;

    return (
      <>
        {screen.isFullScreen && (
          <CancelSVG
            onClick={LayoutService.closefullscreen.bind(LayoutService)}
            className={classnames(styles.fullScreenCloseButton)}
          />
        )}
      </>
    );
  }

  private get sideNavContent() {
    const {
      state: { resumeData, isDark, screen },
      setSideNavBarVisibility,
      setDarkMode,
    }: GlobalContextType = this.context;

    return (
      <SideNavComponent
        setSideNavBarVisibility={setSideNavBarVisibility}
        setDarkMode={setDarkMode}
        resumeData={resumeData}
        isDark={isDark}
        screen={screen}
      />
    );
  }

  private get shareButtonsContent() {
    const { setShareModelVisibility }: GlobalContextType = this.context;
    const handleCopyIconClick = () => {
      navigator?.clipboard?.writeText(window.location.href);
      setShareModelVisibility(false);
    };
    return (
      <div className={classnames(styles.shareButtonsContainer)}>
        {shareLinks.map(({ Button, Icon }, index) => {
          if (Button) {
            return (
              <Button
                key={index}
                className={classnames(styles.shareButtons)}
                url={window.location.href}
                beforeOnClick={setShareModelVisibility.bind(this, false)}
              >
                <Icon size={50} round={true} />
              </Button>
            );
          } else {
            return (
              <Icon
                title={'Copy URL'}
                onClick={handleCopyIconClick}
                key={index}
                className={classnames(styles.shareButtons, styles.copyIcon)}
              />
            );
          }
        })}
      </div>
    );
  }
}
