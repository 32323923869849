import { Dispatch } from 'react';
import {
  IPersonOnObjectProps,
  IPersonProps,
  IPWA,
  IResumeData,
  IScreen,
  ISky,
  Layout,
  ResponsiveMode,
} from '../common/interfaces/common.interface';
import {
  RESET_INTERACTIVE_RESUME_STATE,
  SET_DARK_MODE,
  SET_LAYOUT_SIZE,
  SET_LOADING_STATE,
  SET_QnA_SERVICE_INSTANCE,
  SET_PERSON_IN_CAR_PROPS,
  SET_PERSON_ON_BOAT_PROPS,
  SET_PERSON_ON_TRAIN_PROPS,
  SET_PERSON_PROPS,
  SET_PWA_PROPS,
  SET_RESPONSIVE_MODE,
  SET_RESUME_DATA,
  SET_SCREEN_PROPS,
  SET_SCROLL_TO,
  SET_SHARE_MODEL_VISIBILITY,
  SET_SHOULD_MOVE_SCROLLS,
  SET_SIDE_NAV_BAR_VISIBILITY,
  SET_SKY_PROPS,
} from './action-type-keys.constant';
import {
  IIsDarkStateAction,
  ILayoutAction,
  ILoadingAction,
  IPersonOnObjectPropsAction,
  IPersonPropsAction,
  IPWAAction,
  IQnAServiceAction,
  IResetInteractiveResumeStateAction,
  IResponsiveModeAction,
  IResumeDataAction,
  IScreenAction,
  IScrollAction,
  IScrollPayload,
  IShareModelAction,
  IShouldMoveScrollAction,
  ISideNavBarAction,
  ISkyAction,
} from './actions.interface';
import QnAService from '../common/services/q-n-a.service';

export const actionDispatcher =
  <T, P>(type: T) =>
  (
    dispatch: Dispatch<{
      type: T;
      payload: P;
    }>
  ) =>
  (payload: P) =>
    dispatch({ type, payload });

export const setLayoutSize = actionDispatcher<
  ILayoutAction['type'],
  ILayoutAction['payload']
>(SET_LAYOUT_SIZE);
export type ISetLayoutSize = (payload: Layout) => void;

export const setResponsiveMode = actionDispatcher<
  IResponsiveModeAction['type'],
  IResponsiveModeAction['payload']
>(SET_RESPONSIVE_MODE);
export type ISetResponsiveMode = (payload: ResponsiveMode) => void;

export const setResumeData = actionDispatcher<
  IResumeDataAction['type'],
  IResumeDataAction['payload']
>(SET_RESUME_DATA);
export type ISetResumeData = (payload: IResumeData) => void;

export const setLoadingState = actionDispatcher<
  ILoadingAction['type'],
  ILoadingAction['payload']
>(SET_LOADING_STATE);
export type ISetLoadingState = (payload: boolean) => void;

export const addScroll = actionDispatcher<
  IScrollAction['type'],
  IScrollAction['payload']
>(SET_SCROLL_TO);
export type IAddScroll = (payload: IScrollPayload) => void;

export const setPersonProps = actionDispatcher<
  IPersonPropsAction['type'],
  IPersonPropsAction['payload']
>(SET_PERSON_PROPS);
export type ISetPersonProps = (payload: Partial<IPersonProps>) => void;

export const setPersonOnBoatProps = actionDispatcher<
  IPersonOnObjectPropsAction['type'],
  IPersonOnObjectPropsAction['payload']
>(SET_PERSON_ON_BOAT_PROPS);
export type ISetPersonOnBoatProps = (
  payload: Partial<IPersonOnObjectProps>
) => void;

export const setShouldMoveScrolls = actionDispatcher<
  IShouldMoveScrollAction['type'],
  IShouldMoveScrollAction['payload']
>(SET_SHOULD_MOVE_SCROLLS);
export type ISetShouldMoveScrolls = (payload: boolean) => void;

export const resetInteractiveResumeState = actionDispatcher<
  IResetInteractiveResumeStateAction['type'],
  null
>(RESET_INTERACTIVE_RESUME_STATE);
export type IResetInteractiveResumeState = () => void;

export const setDarkMode = actionDispatcher<
  IIsDarkStateAction['type'],
  IIsDarkStateAction['payload']
>(SET_DARK_MODE);
export type ISetDarkMode = (payload: boolean) => void;

export const setPersonOnTrainProps = actionDispatcher<
  IPersonOnObjectPropsAction['type'],
  IPersonOnObjectPropsAction['payload']
>(SET_PERSON_ON_TRAIN_PROPS);
export type ISetPersonOnTrainProps = (
  payload: Partial<IPersonOnObjectProps>
) => void;

export const setPersonInCarProps = actionDispatcher<
  IPersonOnObjectPropsAction['type'],
  IPersonOnObjectPropsAction['payload']
>(SET_PERSON_IN_CAR_PROPS);
export type ISetPersonInCarProps = (
  payload: Partial<IPersonOnObjectProps>
) => void;

export const setSkyProps = actionDispatcher<
  ISkyAction['type'],
  ISkyAction['payload']
>(SET_SKY_PROPS);
export type ISetSkyProps = (payload: Partial<ISky>) => void;

export const setSideNavBarVisibility = actionDispatcher<
  ISideNavBarAction['type'],
  ISideNavBarAction['payload']
>(SET_SIDE_NAV_BAR_VISIBILITY);
export type ISetSideNavBarVisibility = (payload: boolean) => void;

export const setScreenProps = actionDispatcher<
  IScreenAction['type'],
  IScreenAction['payload']
>(SET_SCREEN_PROPS);
export type ISetScreenProps = (payload: Partial<IScreen>) => void;

export const setPWAProps = actionDispatcher<
  IPWAAction['type'],
  IPWAAction['payload']
>(SET_PWA_PROPS);
export type ISetPWAProps = (payload: Partial<IPWA>) => void;

export const setShareModelVisibility = actionDispatcher<
  IShareModelAction['type'],
  IShareModelAction['payload']
>(SET_SHARE_MODEL_VISIBILITY);
export type ISetShareModelVisibility = (payload: boolean) => void;

export const setQnAServiceInstance = actionDispatcher<
  IQnAServiceAction['type'],
  IQnAServiceAction['payload']
>(SET_QnA_SERVICE_INSTANCE);
export type ISetQnAServiceInstance = (payload: QnAService) => void;
