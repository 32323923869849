import { memo } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import {
  IDefaultResponsiveSupport,
  PAGE_ROUTES,
} from './common/interfaces/common.interface';
import HomePageComponent from './pages/home/home-page.component';
import InteractiveResumePageComponent from './pages/interactive-resume/interactive-resume-page.component';
import PlainResumePageComponent from './pages/plain-resume/plain-resume-page.component';
import AiAssistantComponent from './pages/ai-assistant/ai-assistant.component';

interface IRoutes extends IDefaultResponsiveSupport {}

export default memo(function Routes({ responsiveMode }: IRoutes) {
  return (
    <Switch>
      <Route exact path={PAGE_ROUTES.HOME}>
        <HomePageComponent responsiveMode={responsiveMode} />
      </Route>
      <Route path={PAGE_ROUTES.PLAIN_RESUME}>
        <PlainResumePageComponent responsiveMode={responsiveMode} />
      </Route>
      <Route path={PAGE_ROUTES.INTERACTIVE_RESUME}>
        <InteractiveResumePageComponent responsiveMode={responsiveMode} />
      </Route>
      <Route path={PAGE_ROUTES.AI_ASSISTANT}>
        <AiAssistantComponent responsiveMode={responsiveMode} />
      </Route>
      <Route path='*'>
        
        <Redirect to={PAGE_ROUTES.HOME} />
      </Route>
    </Switch>
  );
});
