// src/hooks/useSpeechRecognition.ts
import { useState, useEffect, useCallback, useMemo } from 'react';

type SpeechRecognitionHook = () => [
  string,
  string,
  boolean,
  number,
  string | null,
  () => void,
  () => void
];
interface SpeechRecognitionEvent {
  resultIndex: number;
  results: {
    isFinal: boolean;
    [key: number]: {
      transcript: string;
    };
  }[];
}
interface SpeechRecognitionErrorEvent {
  error: string;
}

const getRandomIntensity = () => Math.random() * 100;

const useSpeechRecognition: SpeechRecognitionHook = () => {
  const [transcript, setTranscript] = useState<string>('');
  const [interimTranscript, setInterimTranscript] = useState<string>('');
  const [listening, setListening] = useState<boolean>(false);
  const [intensity, setIntensity] = useState<number>(0);
  const [error, setError] = useState<string | null>(null);

  // Check if the browser supports SpeechRecognition
  const { SpeechRecognition, webkitSpeechRecognition } = window as any;

  const recognition = useMemo(() => {
    const rec = new (SpeechRecognition || webkitSpeechRecognition)();
    // Configure recognition settings
    rec.continuous = false; // Keep listening continuously
    rec.interimResults = true; // Enable interim results (real-time transcription)
    rec.lang = 'en-US'; // Language setting
    rec.maxAlternatives = 1; // Set maxAlternatives
    return rec;
  }, [SpeechRecognition, webkitSpeechRecognition]);

  // Start speech recognition
  const startListening = useCallback(() => {
    if (recognition && !listening && !error) {
      console.log('Start Listening');
      setTranscript('');
      setInterimTranscript(''); // Reset interim transcript
      recognition.start();
      setError(null);
      setListening(true);
    }
  }, [recognition]);

  // Stop speech recognition
  const stopListening = useCallback(() => {
    console.log('Stop Listening');
    if (recognition) {
      recognition.stop();
      setListening(false);
    }
  }, [recognition]);

  // Handle results from SpeechRecognition
  useEffect(() => {
    const handleResult = (event: SpeechRecognitionEvent) => {
      let finalTranscript = '';
      let interimTranscript = '';

      // Loop through all the results and separate final and interim results
      for (let i = event.resultIndex; i < event.results.length; i++) {
        const result = event.results[i];
        // Explicitly cast result to the correct type and handle
        if (result.isFinal) {
          finalTranscript += result[0].transcript;
          setIntensity(0);
        } else {
          interimTranscript += result[0].transcript;
          setIntensity(getRandomIntensity());
        }
      }

      // Set the final and interim transcripts
      setTranscript(finalTranscript);
      setInterimTranscript(interimTranscript);
    };

    const handleError = (event: SpeechRecognitionErrorEvent) => {
      console.error('Error occurred in speech recognition:', event.error);
      setError(`Error occurred: ${event.error}`);
      stopListening();
    };

    recognition.onresult = handleResult;
    recognition.onerror = handleError;
    recognition.onspeechend = stopListening;

    // Clean up on unmount
    return () => {
      stopListening();
    };
  }, []);

  return [
    transcript,
    interimTranscript,
    listening,
    intensity,
    error,
    startListening,
    stopListening,
  ];
};

export default useSpeechRecognition;
