import { useState, useEffect } from 'react';

interface SpeechSynthesisHook {
  speak: (text?: string) => void;
  cancelSpeak: () => void;
  speaking: boolean;
}

const useSpeechSynthesis = (lang = 'en-GB'): SpeechSynthesisHook => {
  const [speaking, setSpeaking] = useState(false);
  const synth = window.speechSynthesis;

  const getMaleVoice = () => {
    const voices = synth.getVoices();
    return voices.find(
      (voice) =>
        voice.lang === lang && !voice.name.toLowerCase().includes('female')
    );
  };

  const maleVoice = getMaleVoice();

  const speak = (newText?: string) => {
    if (synth.speaking || synth.pending) {
      console.error('SpeechSynthesis is already speaking.');
      return;
    }

    const utterance = new SpeechSynthesisUtterance(newText);
    if (maleVoice) {
      utterance.voice = maleVoice;
    }
    utterance.onstart = () => setSpeaking(true);
    utterance.onend = () => setSpeaking(false);
    utterance.onerror = () => setSpeaking(false);

    synth.speak(utterance);
  };

  const cancelSpeak = () => {
    if (synth.speaking || synth.pending) {
      synth.cancel();
      setSpeaking(false);
    }
  };

  useEffect(() => {
    window.addEventListener("beforeunload", cancelSpeak);
    return () => {
      cancelSpeak();
      window.removeEventListener("beforeunload", cancelSpeak);
    };
  }, [synth]);

  return { speak, cancelSpeak, speaking };
};

export default useSpeechSynthesis;
