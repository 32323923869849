import { IGlobalState } from '../common/interfaces/common.interface';
import {
  RESET_INTERACTIVE_RESUME_STATE,
  SET_DARK_MODE,
  SET_LAYOUT_SIZE,
  SET_LOADING_STATE,
  SET_PERSON_IN_CAR_PROPS,
  SET_PERSON_ON_BOAT_PROPS,
  SET_PERSON_ON_TRAIN_PROPS,
  SET_PERSON_PROPS,
  SET_PWA_PROPS,
  SET_QnA_SERVICE_INSTANCE,
  SET_RESPONSIVE_MODE,
  SET_RESUME_DATA,
  SET_SCREEN_PROPS,
  SET_SCROLL_TO,
  SET_SHARE_MODEL_VISIBILITY,
  SET_SHOULD_MOVE_SCROLLS,
  SET_SIDE_NAV_BAR_VISIBILITY,
  SET_SKY_PROPS,
} from './action-type-keys.constant';
import { IGlobalActions, IScrollAction } from './actions.interface';
import { initialState } from './global.context';

const updateScrollState = (
  state: IGlobalState,
  action: IScrollAction
): IGlobalState => {
  if (!state.shouldMoveScrolls) return state;

  const updateScroll =
    state.scrollPostions[action.payload.key] + action.payload.value;
  return {
    ...state,
    scrollPostions: {
      ...state.scrollPostions,
      [action.payload.key]: updateScroll > 0 ? updateScroll : 0,
    },
  };
};

function reducer(state: IGlobalState, action: IGlobalActions): IGlobalState {
  switch (action.type) {
    case SET_LAYOUT_SIZE:
      return { ...state, layout: action.payload };
    case SET_RESPONSIVE_MODE:
      return { ...state, responsiveMode: action.payload };
    case SET_RESUME_DATA:
      return { ...state, resumeData: action.payload };
    case SET_LOADING_STATE:
      return { ...state, isLoading: action.payload };
    case SET_DARK_MODE:
      return { ...state, isDark: action.payload };
    case SET_SCROLL_TO:
      return updateScrollState(state, action);
    case SET_PERSON_PROPS:
      return {
        ...state,
        personProps: {
          ...state.personProps,
          ...action.payload,
        },
      };
    case SET_PERSON_ON_BOAT_PROPS:
      return {
        ...state,
        personOnBoatProps: {
          ...state.personOnBoatProps,
          ...action.payload,
        },
      };
    case SET_PERSON_ON_TRAIN_PROPS:
      return {
        ...state,
        personOnTrainProps: {
          ...state.personOnTrainProps,
          ...action.payload,
        },
      };
    case SET_PERSON_IN_CAR_PROPS:
      return {
        ...state,
        personInCarProps: {
          ...state.personInCarProps,
          ...action.payload,
        },
      };
    case SET_SHOULD_MOVE_SCROLLS:
      return {
        ...state,
        shouldMoveScrolls: action.payload,
      };
    case RESET_INTERACTIVE_RESUME_STATE:
      return {
        ...initialState,
        responsiveMode: state.responsiveMode,
        resumeData: state.resumeData,
        layout: { ...state.layout },
        isDark: state.isDark,
        screen: state.screen,
      };
    case SET_SKY_PROPS:
      return {
        ...state,
        sky: {
          ...state.sky,
          ...action.payload,
        },
      };
    case SET_SIDE_NAV_BAR_VISIBILITY:
      return {
        ...state,
        isSideBarVisible: action.payload,
      };
    case SET_SCREEN_PROPS:
      return {
        ...state,
        screen: {
          ...state.screen,
          ...action.payload,
        },
      };
    case SET_PWA_PROPS:
      return {
        ...state,
        pwa: {
          ...state.pwa,
          ...action.payload,
        },
      };
    case SET_SHARE_MODEL_VISIBILITY:
      return {
        ...state,
        isShareModelVisible: action.payload,
      };
    case SET_QnA_SERVICE_INSTANCE:
      return {
        ...state,
        qnaService: action.payload,
      };
    default:
  }
  return state;
}

export default reducer;
