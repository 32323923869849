import React, { useContext, useEffect, useState } from 'react';
import {
  IDefaultResponsiveSupport,
  IGlobalState,
} from '../../common/interfaces/common.interface';
import useSpeechRecognition from '../../hooks/speech-recognition/speech-recognition.hook';
import { Context as GlobalContext } from '../../context/global.context';
import useSpeechSynthesis from '../../hooks/speech-synthesis/speech-synthesis.hook';
import {
  OPENAI_STATUS_CODE_MSG,
  SPEECH_MESSAGES,
} from '../../common/constant/common.constant';
import styles from './ai-assistant.module.scss';
import MicComponent from '../../components/mic/mic.component';
import { classnames } from '../../common/general-utilities/general-utilities';

interface IAiAssistantComponent extends IDefaultResponsiveSupport {
  className?: string;
}

const AiAssistantComponent: React.FC<IAiAssistantComponent> = ({
  className,
  responsiveMode,
}) => {
  const context = useContext(GlobalContext);
  const qnaService = (context.state as IGlobalState).qnaService;
  const [loadingAnswer, setLoadingAnswer] = useState(false);
  const [aiResponse, setAIResponse] = useState('');

  const [
    transcript,
    interimTranscript,
    listening,
    intensity,
    error,
    startListening,
    stopListening,
  ] = useSpeechRecognition();
  const { speak, cancelSpeak, speaking } = useSpeechSynthesis();

  useEffect(() => {
    if (speaking) {
      setLoadingAnswer(false);
    } else {
      setAIResponse('');
    }
  }, [speaking]);

  const startTheSpeech = (message: string) => {
    setTimeout(() => speak(message), 500);
  };

  useEffect(() => {
    if (transcript && qnaService) {
      console.log('Transcript:', transcript);
      setLoadingAnswer(true);
      qnaService
        .getHuggingResponse(transcript)
        .then((response) => {
          const message = response?.replace(/\n/g, ' ').replace(/\t/g, ' ').replace(/\s+/g, ' ').trim()
          console.log(`AI responce length(${message?.length}):`, message);
          if(message?.length > 0) {
            setAIResponse(message);
            startTheSpeech(message);
          } else {
            setAIResponse(SPEECH_MESSAGES.NOT_FOUND);
            startTheSpeech(SPEECH_MESSAGES.NOT_FOUND);
          }

        })
        .catch((e) => {
          console.log('AI error:', e);
          const message =
            OPENAI_STATUS_CODE_MSG[e?.response?.status] ||
            SPEECH_MESSAGES.SOMTHING_WENT_WRONG;
          startTheSpeech(message);
          setAIResponse(message);
          if (!OPENAI_STATUS_CODE_MSG[e?.response?.status]) {
            console.error('NO ERROR MESSAGE FOUND FOR:', e);
          }
        });
    }
  }, [transcript]);

  const micOnClick = () => {
    cancelSpeak();
    if (listening) {
      stopListening();
    } else {
      startListening();
    }
  };
  return (
    <div className={styles.aiAssistantContainer}>
      {(listening || loadingAnswer || speaking) &&
        (interimTranscript || transcript || aiResponse) && (
          <div
            className={classnames(styles.transcriptContainer, {
              [styles.speaking]: speaking,
              [styles.loadingAnswer]: loadingAnswer,
            })}
          >
            <p>
              {listening
                ? interimTranscript
                : loadingAnswer
                ? transcript
                : speaking
                ? aiResponse
                : ''}
            </p>
          </div>
        )}
      <MicComponent
        className={className}
        isActive={listening}
        onClick={micOnClick}
        intensity={intensity}
        loading={loadingAnswer}
        aiAnswering={speaking}
        responsiveMode={responsiveMode}
      />
    </div>
  );
};

export default AiAssistantComponent;
