import React from 'react';
import { Link } from 'react-router-dom';
import { profileAvatar } from '../../../common/constant/interactive.resume.constant';
import { classnames } from '../../../common/general-utilities/general-utilities';
import { IDefaultResponsiveSupport } from '../../../common/interfaces/common.interface';
import ResponsiveModeService from '../../../common/services/responsive-mode.service';
import IntersectionObserverComponent from '../../../components/intersection-observer.component';
import styles from './resume-header.component.module.scss';
import AiAssistantComponent from '../../ai-assistant/ai-assistant.component';

interface IResumeHeader extends IDefaultResponsiveSupport {
    name?: string;
    profileName?: string;
    resumePath?: string;
    skill?: string;
}
export default class ResumeHeaderComponent extends React.PureComponent<IResumeHeader> {
    render() {
        const { responsiveMode, name, profileName, resumePath, skill } = this.props;

        return (
            <div className={classnames(styles.header, ResponsiveModeService.responsiveModeClass(styles, responsiveMode))}>
                <div className={styles.headerLeftContainer}>
                    <div style={{ backgroundImage: profileAvatar }} className={styles.profilePhoto}></div>
                </div>
                <div className={styles.headerRightContainer}>
                    <h1 className={styles.candidateName}>
                        <IntersectionObserverComponent animationDelay={0.3} className={'fadeInAnimation'} animationName={'fadeInFromRight'} observerOneTime={true}>
                            {name}
                        </IntersectionObserverComponent>
                    </h1>
                    <p className={styles.profileName}>
                        <IntersectionObserverComponent animationDelay={0.5} className={'fadeInAnimation'} animationName={'fadeInFromRight'} observerOneTime={true}>
                            {profileName}
                        </IntersectionObserverComponent>
                    </p>
                    <p className={styles.skill}>
                        <IntersectionObserverComponent animationDelay={0.5} className={'fadeInAnimation'} animationName={'fadeInFromRight'} observerOneTime={true}>
                            ( {skill} )
                        </IntersectionObserverComponent>
                    </p>
                    <div className={styles.btnContainer}>
                        <IntersectionObserverComponent animationDelay={0.7} className={'fadeInAnimation'} animationName={'fadeInFromRight'} observerOneTime={true}>
                            <a className={styles.downloadCVBtn} href={resumePath} download={name?.split(' ')?.join('_') + '_Resume'}>Download CV</a>
                            <Link to="/interactive-resume" className={styles.interativeBtn}>Interactive Resume</Link>
                        </IntersectionObserverComponent>
                    </div>
                    
                </div>
            </div>
        );
    }
}