import { calculateAge, getOrdinalNumber } from '../general-utilities/general-utilities';
import { IAboutMe, IResumeData } from '../interfaces/common.interface';
import { Configuration, OpenAIApi } from 'openai';

export interface QnAAnswer {
  text: string;
  score: number;
}

const HUGGING_API_URL =
  'https://api-inference.huggingface.co/models/deepset/roberta-base-squad2';

export default class QnAService {
  private _fullContext: string;
  private _resumeData: IResumeData;
  private _openai: OpenAIApi;

  constructor(resumeData: IResumeData) {
    this._resumeData = resumeData;
    this._fullContext = this.generateFullPrompt;
    const configuration = new Configuration({
      apiKey: this._resumeData.openai_key,
      // apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    });
    this._openai = new OpenAIApi(configuration);
  }

  async getOpenaiResponse(query: string): Promise<string> {
    try {
      const chatCompletion = await this._openai.createChatCompletion({
        model: 'gpt-4o-mini',
        messages: [
          { role: 'system' as const, content: this.getRelevantContext(query) },
          { role: 'user', content: query },
        ],
        max_tokens: 500,
      });
      return chatCompletion.data.choices[0].message?.content!;
    } catch (e) {
      throw e;
    }
  }

  async getHuggingResponse(query: string): Promise<string> {
    const context = this.getRelevantContext(query);
    console.log('Context:', context);
    try {
      const response = await fetch(HUGGING_API_URL, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${this._resumeData.hugging_key}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          inputs: { question: query, context },
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      return data.answer || 'No answer found.';
    } catch (error) {
      throw error;
    }
  }

  private getRelevantContext(question: string): string {
    let context = this.generateStartPrompt;
    let foundContext = false;
    if (
      this.containsSearchTerms(question, [
        'personal',
        'details',
        'birth',
        'address',
        'mobile',
        'email',
        'portfolio',
        'website',
        'contact',
        'name',
        'age',
        'old'
      ])
    ) {
      context += this.generatePersonalDetailsPrompt;
      foundContext = true;
    }
    if (
      this.containsSearchTerms(question, [
        'introduction',
        'intro',
        'yourself',
        'sunil',
        'experience',
      ])
    ) {
      context += this.generateIntroPrompt;
      foundContext = true;
    }
    if (
      this.containsSearchTerms(question, [
        'technical',
        'skill',
        'programming',
        'language',
        'technology'
      ])
    ) {
      context += this.generateTechnicalPrompt;
      foundContext = true;
    }
    if (
      this.containsSearchTerms(question, [
        'company',
        'work',
        'employment',
        'job',
        'designation',
        'position',
      ])
    ) {
      context += this.generateEmploymentPrompt;
      foundContext = true;
    }
    if (
      this.containsSearchTerms(question, [
        'education',
        'institute',
        'exam',
        'result',
        'BTech',
        '12th',
        '10th',
        'college',
        'school',
        'engineering',
        'class',
      ])
    ) {
      context += this.generateEducationPrompt;
      foundContext = true;
    }
    if (this.containsSearchTerms(question, ['certification'])) {
      context += this.generateCertificationPrompt;
      foundContext = true;
    }
    if (this.containsSearchTerms(question, ['project'])) {
      context += this.generateProjectDetailsPrompt;
      foundContext = true;
    }
    // context += this.generateEndPrompt;
    return foundContext ? context : this._fullContext;
  }

  private get generatePersonalDetailsPrompt() {
    const { aboutMe } = this._resumeData;
    return `\nPersonal Details:
      - Name: ${aboutMe.name}
      - Date of Birth(DOB): ${aboutMe.contactInfo.DOB}
      - Age: ${calculateAge('1989-08-01')}
      - Address: ${aboutMe.contactInfo.Address}
      - Mobile: ${aboutMe.contactInfo.Mobile}
      - Email: ${aboutMe.contactInfo.Email}
      - Portfolio website: ${aboutMe.portfolio}\n`;
  }

  private get generateIntroPrompt() {
    const { aboutMe } = this._resumeData;
    return `\n${aboutMe.name}'s introduction: '${aboutMe.summary
      .replace('#SKILL', aboutMe.skill?.replaceAll('+', ','))
      .replace('#YEAR', this.calculateYears('2013-12-10').toString())
      .replaceAll(/<\/?b>/g, '')}\n`;
  }

  private get generateTechnicalPrompt() {
    const { aboutMe } = this._resumeData;
    return `\n${aboutMe.name} is proficient in the following programming languages and technologies - ${aboutMe.skill?.replaceAll('+', ',')}\n`;
  }

  private get generateEmploymentPrompt() {
    const { employmentHistory, aboutMe } = this._resumeData;
    const employmentList = employmentHistory.slice(1).reverse();
    return `\nEmployment History:
    ${aboutMe.name} has worked in total of ${
      employmentList.length
    } companies names are ${employmentList?.map(
      (job) => `${job.organisation}`
    )}.\n Below are the details:
    ${employmentList
      .map((job, i) => {
        const isOrWas = i === employmentList.length - 1 ? 'is' : 'was';
        return `${getOrdinalNumber(i + 1)} companay name ${isOrWas} ${
          job.organisation
        }, duration ${isOrWas} from ${
          job.shortPeriod
        } and designation/position ${isOrWas} ${job.designation}.`;
      })
      .join('\n\t')}\n`;
  }

  private get generateEducationPrompt() {
    const { education } = this._resumeData;
    return `\nEducation:
    ${education
      .map((edu) =>
        `- ${edu.exam} from ${edu.institute} (${edu.year}), Result: ${edu.result}`.replaceAll(
          /<\/?sup>/g,
          ''
        )
      )
      .join('\n\t')}\n`;
  }

  private get generateCertificationPrompt() {
    const { certifications, aboutMe } = this._resumeData;
    return `\nCertifications:
    ${aboutMe.name} has done total of ${
      certifications.length
    } certifications(${certifications?.map(
      (item) => item.split(':')[0]
    )}).\n Below are the list of certifications:
    ${certifications
      .map((item, i) => `${getOrdinalNumber(i + 1)} certification is ${item}`)
      .join('\n\t')}\n`;
  }

  private get generateProjectDetailsPrompt() {
    const { aboutMe, projects } = this._resumeData;
    const projectList = [...projects].reverse();
    return `\nProjects:
    ${aboutMe.name} has worked on total ${projects.length} projects.
    Below are list of projects:
    ${projectList
      .map((project, i) => {
        const isOrWas = i === projectList.length - 1 ? 'is' : 'was';
        return `${getOrdinalNumber(i + 1)} ${
          i === projectList.length - 1
            ? 'and latest project currently working in '
            : `project ${isOrWas}`
        } ${project.projectName}, ${
          project.period ? `he work from  ${project.period}` : ''
        }. ${
          project.projectName === 'LoharTalk'
            ? project.projectOverview.split('<br>')[0]
            : project.projectOverview
        }`;
      })
      .join('\n\t')}\n`;
  }

  private get generateStartPrompt() {
    const { aboutMe } = this._resumeData;
    return `\n${aboutMe.name} is a ${
      aboutMe.profile
    } with ${this.calculateYears(
      '2013-12-10'
    )}+ years of experience in software development. He currently works as ${aboutMe.designation}.\n`;
  }

  private get generateEndPrompt() {
    return `\nOnly answer questions related to Sunil Lohar. If a question is unrelated, politely decline.\n`;
  }

  private get generateFullPrompt() {
    const prompt = `
    ${this.generateStartPrompt}
    ${this.generatePersonalDetailsPrompt}
    ${this.generateIntroPrompt}
    ${this.generateTechnicalPrompt}
    ${this.generateEmploymentPrompt}
    ${this.generateEducationPrompt}
    ${this.generateCertificationPrompt}
    ${this.generateProjectDetailsPrompt}
    `;
    return prompt;
  }

  private calculateYears(startDate: string): number {
    const start = new Date(startDate);
    const current = new Date();
    return current.getFullYear() - start.getFullYear();
  }

  private containsSearchTerms(str: string, searchArray: string[]): boolean {
    return searchArray.some((term) =>
      str.toLocaleLowerCase().includes(term.toLocaleLowerCase())
    );
  }
}
