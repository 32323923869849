import {
  IGlobalState,
  SCREEN_ORIENTATION,
} from '../common/interfaces/common.interface';
import { IGlobalActions } from './actions.interface';
import CreateDataContext from './create-data.context';
import {
  Directions,
  ScrollWindowList,
} from '../common/constant/interactive.resume.constant';
import {
  setLayoutSize,
  setResponsiveMode,
  setResumeData,
  setLoadingState,
  addScroll,
  setPersonProps,
  setShouldMoveScrolls,
  setPersonOnBoatProps,
  setPersonOnTrainProps,
  setPersonInCarProps,
  resetInteractiveResumeState,
  setDarkMode,
  setSkyProps,
  setPWAProps,
  setSideNavBarVisibility,
  setScreenProps,
  setShareModelVisibility,
  ISetLayoutSize,
  ISetResponsiveMode,
  ISetResumeData,
  ISetLoadingState,
  IAddScroll,
  ISetPersonProps,
  ISetPersonOnBoatProps,
  ISetShouldMoveScrolls,
  IResetInteractiveResumeState,
  ISetDarkMode,
  ISetPersonOnTrainProps,
  ISetPersonInCarProps,
  ISetSkyProps,
  ISetSideNavBarVisibility,
  ISetScreenProps,
  ISetPWAProps,
  ISetShareModelVisibility,
  setQnAServiceInstance,
  ISetQnAServiceInstance
} from './action';
import React from 'react';
import reducer from './global.reducer';
import interactiveStyle from '../pages/interactive-resume/interactive-resume-page.module.scss';
import { CLOUD_TYPES } from '../pages/interactive-resume/cloud/cloud.component';
import { isFullScreen, mediaOrientationQueryList } from '../common/general-utilities/general-utilities';

export const initialState: IGlobalState = {
  layout: {
    width: 0,
    height: 0,
  },
  isDark: false,
  responsiveMode: 'desktop',
  resumeData: {},
  isLoading: false,
  shouldMoveScrolls: false,
  sky: {
    backgroundColor: interactiveStyle.skyColor1,
    cloudType: CLOUD_TYPES.CLOUD_TYPE_1,
    isStarVisible: false,
  },
  scrollPostions: {
    [ScrollWindowList.PLATFORM]: 0,
    [ScrollWindowList.CLOUD]: 0,
    [ScrollWindowList.MOUNTAIN_AND_CITY]: 0,
  },
  personProps: {
    isVisible: true,
    makeJump: false,
    isRunning: false,
    direction: Directions.ArrowRight,
    ref: React.createRef(),
    shadow: React.createRef(),
    nextPlatform: null,
    currentPlatform: null,
    getBoundingClientRect: {
      left: 0,
      right: 0,
      width: 0,
      height: 0,
    },
  },
  personOnBoatProps: {
    isVisible: false,
    ref: React.createRef(),
    getBoundingClientRect: {
      left: 0,
      right: 0,
      width: 0,
      height: 0,
    },
  },
  personOnTrainProps: {
    isVisible: false,
    ref: React.createRef(),
    getBoundingClientRect: {
      left: 0,
      right: 0,
      width: 0,
      height: 0,
    },
  },
  personInCarProps: {
    isVisible: false,
    ref: React.createRef(),
    getBoundingClientRect: {
      left: 0,
      right: 0,
      width: 0,
      height: 0,
    },
  },
  isSideBarVisible: false,
  screen: {
    isFullScreen: isFullScreen(),
    orientation: mediaOrientationQueryList.matches ? SCREEN_ORIENTATION.PORTRAIT : SCREEN_ORIENTATION.LANDSCAPE,
  },
  pwa: {
    newVersionAvailable: false,
    waitingWorker: null,
    installPromptEvent: null
  },
  isShareModelVisible: false,
  qnaService: null
};

export type GlobalContextType = {
  state: IGlobalState;
  setLayoutSize: ISetLayoutSize;
  setResponsiveMode: ISetResponsiveMode;
  setResumeData: ISetResumeData;
  setLoadingState: ISetLoadingState;
  addScroll: IAddScroll;
  setPersonProps: ISetPersonProps;
  setShouldMoveScrolls: ISetShouldMoveScrolls;
  setPersonOnBoatProps: ISetPersonOnBoatProps;
  setPersonOnTrainProps: ISetPersonOnTrainProps;
  resetInteractiveResumeState: IResetInteractiveResumeState;
  setDarkMode: ISetDarkMode;
  setPersonInCarProps: ISetPersonInCarProps;
  setSkyProps: ISetSkyProps;
  setSideNavBarVisibility: ISetSideNavBarVisibility;
  setScreenProps: ISetScreenProps;
  setPWAProps: ISetPWAProps;
  setShareModelVisibility: ISetShareModelVisibility;
  setQnAServiceInstance: ISetQnAServiceInstance;
};

export const { Context, Provider } = CreateDataContext<
  IGlobalState,
  IGlobalActions
>(
  reducer,
  {
    setLayoutSize,
    setResponsiveMode,
    setResumeData,
    setLoadingState,
    addScroll,
    setPersonProps,
    setShouldMoveScrolls,
    setPersonOnBoatProps,
    setPersonOnTrainProps,
    resetInteractiveResumeState,
    setDarkMode,
    setPersonInCarProps,
    setSkyProps,
    setSideNavBarVisibility,
    setScreenProps,
    setPWAProps,
    setShareModelVisibility,
    setQnAServiceInstance,
  },
  initialState
);
