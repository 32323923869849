import React from 'react';
import styles from './mic.module.scss';
import { MicSVG, SpeakerSVG } from '../../common/constant/resume-data.constant';
import { classnames } from '../../common/general-utilities/general-utilities';
import { IDefaultResponsiveSupport } from '../../common/interfaces/common.interface';
import ResponsiveModeService from '../../common/services/responsive-mode.service';

interface MicComponentProps extends IDefaultResponsiveSupport {
  intensity: number;
  isActive?: boolean;
  onClick?: () => void;
  loading?: boolean;
  className?: string;
  aiAnswering?: boolean;
}

const MicComponent: React.FC<MicComponentProps> = ({
  intensity = 0,
  isActive = false,
  onClick,
  loading = false,
  aiAnswering = false,
  responsiveMode,
}) => {
  return (
    <div
      role="button"
      tabIndex={0}
      className={classnames(
        styles.micContainer,
        ResponsiveModeService.responsiveModeClass(styles, responsiveMode),
        {
          [styles.disabled]: loading,
        }
      )}
      aria-label="Tap microphone to try again"
      onClick={onClick}
      data-state={isActive ? 'listening' : 'try-again'}
      aria-disabled={loading}
      title="Tap microphone to try AI Assistant"
    >
      {loading && !aiAnswering && <div className={styles.micLoader} />}
      <div
        className={classnames(styles.micPulse, {
          [styles.active]: intensity > 0 ? false : aiAnswering || isActive,
        })}
      />
      <div
        style={{ transform: `scale(${1 + intensity / 100})` }}
        className={styles.micLevel}
      />
      <div
        className={classnames(styles.micCircle, {
          [styles.active]: isActive && !aiAnswering && !loading,
          [styles.aiAnswering]: aiAnswering,
          [styles.loading]: loading,
        })}
      />
      {aiAnswering && !loading && !isActive ? (
        <SpeakerSVG className={styles.speaker} />
      ) : (
        <MicSVG
          className={classnames(styles.mic, { [styles.active]: isActive })}
        />
      )}
    </div>
  );
};

export default MicComponent;
