export const SPEECH_MESSAGES = {
  OVERLOADED:
    "Apologies, I'm handling too many requests. Please try again soon.",
  UNAUTHORIZED:
    "Sorry, I'am experiencing connectivity issues. Please try again later.",
  SOMTHING_WENT_WRONG: 'Sorry, Something went wrong. Please try again later.',
  NOT_FOUND: `I'm sorry, but I couldn't find an answer to your question.`
};

export const OPENAI_STATUS_CODE_MSG: { [key: number]: string } = {
  429: SPEECH_MESSAGES.OVERLOADED,
  401: SPEECH_MESSAGES.UNAUTHORIZED,
  403: SPEECH_MESSAGES.UNAUTHORIZED,
};
